import Types from '../types/userSettings';

export const defaultUserSettingsData = {
  nav: {
    isStay: false,
  },
  rollSheet: {
    program: '',
  },
  myApprovals: {
    loadForUser: '',
    program: '',
  },
  pendingAuth: {
    loadForUser: '',
    program: '',
  },
  allApprovals: {
    loadForUser: '',
    program: '',
  },
  participants: {
    program: '',
  },
  participantDetail: {
    timeEntires: '',
  },
  schedules: {
    sort: '',
    shift: '',
  },
  employees: {
    program: '',
  },
  services: {
    program: '',
  },
  participantSettings: {
    newPIPChanged: false,
  },
  roll_sheet_review: {
    program: 0,
    participant: 0,
  },
};

const initialState = {
  settings: defaultUserSettingsData,
  updateUserSettings: {
    loading: false,
    data: {},
    error: '',
  },
  submissions: {
    page: 1,
    search: '',
  },
  approvals: {
    page: 1,
    search: '',
  },
  pendingAuth: {
    page: 1,
    search: '',
  },
};

export default function userSettings(state = initialState, action) {
  const { key, attr, value } = action.payload || {};
  switch (action.type) {
    case Types.SET_OTHER_SETTINGS:
      return {
        ...state,
        [key]: {
          ...state[key],
          [attr]: value,
        },
      };
    case Types.SET_USER_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [key]: {
            ...(state.settings[key] || {}),
            [attr]: value,
          },
        },
      };
    case Types.SET_WHOLE_USER_SETTINGS:
      return {
        ...state,
        settings: action.payload,
      };
    case Types.UPDATE_USER_SETTINGS_REQUEST:
      return {
        ...state,
        updateUserSettings: {
          ...state.updateUserSettings,
          loading: true,
        },
      };
    case Types.UPDATE_USER_SETTINGS_SUCCESS:
      return {
        ...state,
        updateUserSettings: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.UPDATE_USER_SETTINGS_ERROR:
      return {
        ...state,
        updateUserSettings: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.SET_PARTICIPANT_SETTINGS:
      return {
        ...state,
        participantSettings: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
}
