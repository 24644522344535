import createTypes from 'redux-create-action-types';

export default createTypes(
  'GET_USER_CERTIFICATES_REQUEST',
  'GET_USER_CERTIFICATES_SUCCESS',
  'GET_USER_CERTIFICATES_ERROR',
  'GET_USER_EXPIRED_CERTIFICATES_REQUEST',
  'GET_USER_EXPIRED_CERTIFICATES_SUCCESS',
  'GET_USER_EXPIRED_CERTIFICATES_ERROR',
  'SYNC_UPDATE_CERTIFICATES',
  'GET_CERTIFICATES_REQUEST',
  'GET_CERTIFICATES_SUCCESS',
  'GET_CERTIFICATES_ERROR',
  'GET_CERTIFICATE_APPROVALS_REQUEST',
  'GET_CERTIFICATE_APPROVALS_SUCCESS',
  'GET_CERTIFICATE_APPROVALS_ERROR',
  'DENY_CERTIFICATE_APPROVAL_REQUEST',
  'DENY_CERTIFICATE_APPROVAL_SUCCESS',
  'DENY_CERTIFICATE_APPROVAL_ERROR',
  'APPROVE_CERTIFICATE_APPROVAL_REQUEST',
  'APPROVE_CERTIFICATE_APPROVAL_SUCCESS',
  'APPROVE_CERTIFICATE_APPROVAL_ERROR',
  'UPLOAD_USER_CERTIFICATE_REQUEST',
  'UPLOAD_USER_CERTIFICATE_SUCCESS',
  'UPLOAD_USER_CERTIFICATE_ERROR'
);
