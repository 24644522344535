import { combineReducers } from 'redux';
import Types from 'store/types/auth';
import Preferences from './preferences';
import Auth from './auth';
import Employees from './employees';
import Metadata from './metadata';
import Participants from './participants';
import RollSheet from './rollsheet';
import participantProfile from './participantProfile';
import Location from './locations';
import services from './services';
import csr from './csr';
import Schedule from './schedule';
import userSettings from './userSettings';
import csrSummary from './csrSummary';
import timer from './timer';
import guideline from './guideline';
import submitted from './submitted';
import payroll from './payroll';
import participantSchedule from './participantSchedule';
import notification from './notification';
import employeeSchedule from './employeeSchedule';
import announcement from './announcement';
import training from './training';
import ddaSchedule from './dda-schedule';
import hourlySchedule from './hourlySchedule';
import appSettings from './appSettings';
import nightAssist from './nightAssist';
import roster from './roster';
import employeeApproval from './employeeApproval';
import dashboard from './dashboard';
import fillShift from './fillShift';
import mileage from './mileage';
import recurringSchedule from './recurringSchedule';
import employeeAvailability from './employeeAvailability';
import swapShift from './swapShift';
import bonusReimbursement from './bonusReimbursement';
import shift from './shift';
import bonus from './bonus';
import employeeDetail from './employeeDetail';
import quickbook from './quickbook';
import trialsApprovals from './trialsApprovals';
import certificate from './certificate';
const reducers = {
  auth: Auth,
  preferences: Preferences,
  employees: Employees,
  metadata: Metadata,
  participants: Participants,
  rollSheet: RollSheet,
  locations: Location,
  services,
  participantProfile,
  csr,
  schedule: Schedule,
  userSettings,
  csrSummary,
  timer,
  guideline,
  submitted,
  payroll,
  participantSchedule,
  notification,
  employeeSchedule,
  announcement,
  training,
  ddaSchedule,
  hourlySchedule,
  appSettings,
  nightAssist,
  roster,
  employeeApproval,
  dashboard,
  fillShift,
  mileage,
  recurringSchedule,
  employeeAvailability,
  swapShift,
  bonusReimbursement,
  shift,
  bonus,
  employeeDetail,
  quickbook,
  trialsApprovals,
  certificate,
};

const combinedReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
  if (action.type === Types.LOGOUT_SUCCESS) {
    state = undefined;
  }

  return combinedReducer(state, action);
};

export default rootReducer;
