export const TIME_OFF_TYPE = {
  SINGLE_DAY: 'Single Day',
  MULTI_DAY: 'Multi Day',
  PART_DAY: 'Partial Day',
};
export const TIME_OFF_LABEL_TYPE = {
  SINGLE_DAY: 'Full Day',
  MULTI_DAY: 'Multi Day',
  PART_DAY: 'Part Day',
};
export const TIME_OFF_FORMAT = 'MM-DD-YYYY';
export const PART_TIME_OFF_FORMAT = 'MM-DD-YYYY, h:mm A';
export const DATE_COMPARE_FORMAT = 'DD/MM/YYYY';
export const DATE_BE_FORMAT = 'YYYY-MM-DD';
export const DATE_BE_FORMAT_1 = 'YYYY/MM/DD';
export const DATE_FE_FORMAT = 'MMM DD, YYYY';
export const FULL_DT_FORMAT = 'ddd MM/DD/YYYY hh:mm A';
export const FULL_DT_LOWER_FORMAT = 'ddd MM/DD/YYYY hh:mm A';
export const SHORT_DATE_FORMAT = 'MMM DD';
export const EMPTY_SHIFT_OPTION = {
  value: '0',
  label: 'Empty Shift',
};
export const SPECIAL_SHIFT_TYPE = 'not_assigned';
export const DEFAULT_LOCATION = {
  lat: 44.295894,
  lng: -114.736846,
};
export const DEFAULT_EMPLOYEE_PAYROLL_PAGE = {
  employeeId: 0,
  payroll: {
    page: 1,
    existingStartDate: {},
  },
  // bonus: {
  //   page: 1,
  //   existingStartDate: {},
  // },
  // reim: {
  //   page: 1,
  //   existingStartDate: {},
  // },
  dateRange: [null, null],
};
export const CURRENT_ENV_VARS = {
  'https://lifeinc-dev-api.ventive.app': 'DEV',
  'https://lifeinc-staging-api.ventive.app': 'STAGING',
  'https://api.lifeincidaho.com': 'PROD',
};
export const CURRENT_ENV = CURRENT_ENV_VARS[process.env.REACT_APP_API_URL];
export const OBJECTIVE_SCORES = ['5', '4', '3', '2', '1', '0', 'R', 'I'];
export const OBJECTIVE_SCORES_VALUE_MAPPING = {
  '5': 5,
  '4': 4,
  '3': 3,
  '2': 2,
  '1': 1,
  '0': 0,
  R: -1,
  I: -2,
};
export const OBJECTIVE_SCORES_LABEL_MAPPING = {
  '5': '5',
  '4': '4',
  '3': '3',
  '2': '2',
  '1': '1',
  '0': '0',
  '-1': 'R',
  '-2': 'I',
};
export const FILE_MIME_TYPES = {
  IMAGE: {
    PNG: 'image/png',
    JPEG: 'image/jpeg',
    HEIC: 'image/heic',
  },
  FILE: {
    PDF: 'application/pdf',
  },
};
export const CERTIFICATE_IMAGE_ACCEPT = [
  FILE_MIME_TYPES.IMAGE.PNG,
  FILE_MIME_TYPES.IMAGE.JPEG,
  FILE_MIME_TYPES.IMAGE.HEIC,
].join(', ');
export const CERTIFICATE_FILE_ACCEPT = [
  FILE_MIME_TYPES.FILE.PDF,
  FILE_MIME_TYPES.IMAGE.PNG,
  FILE_MIME_TYPES.IMAGE.JPEG,
  FILE_MIME_TYPES.IMAGE.HEIC,
].join(', ');
