import React from 'react';
import { Layout } from 'antd';
import get from 'lodash/get';
import cs from 'classnames';
import moment from 'moment-timezone';
import { useSelector, useDispatch } from 'react-redux';
import { Route, Switch, Redirect } from 'react-router-dom';
import AlertPopup from 'components/AlertPopup';
import AlertPopupHandler from 'components/AlertPopup/AlertPopupHandler';
import { permissions, canAccess } from 'helpers/permission';
import { apiUpdateUserSignature } from 'api/auth';
import { syncUpdateProfile } from 'store/actions/auth';
import Notifications from 'components/Notification';
import NotificationHandler from 'components/Notification/NotificationHandler';
import SignatureModal from 'components/SignatureModal';
import asyncComponent from 'util/asyncComponent';
import Sidebar from '../Sidebar/index';
import Topbar from '../Topbar/index';
import ViewAnnouncementsModal from '../ViewAnnouncementsModal';
import classes from './MainApp.module.scss';
import { TAB_SIZE } from '../../constants/ThemeSetting';

const { Content, Footer } = Layout;

const MainApp = ({ location }) => {
  const dispatch = useDispatch();
  const currentUser = useSelector(({ auth }) => auth.user);
  const userTimeZone = currentUser.timezone;
  moment.tz.setDefault(userTimeZone);
  const width = useSelector(({ preferences }) => get(preferences, 'width'));
  const isStopButton = useSelector(({ timer }) =>
    get(timer, 'isStarting', false)
  );
  const { pathname, search } = location || {};
  const isParticipantScheduleUrl =
    (pathname || '').includes('/participants/') &&
    (search || '').includes('?tab=schedule');
  const isPayrollUrl = (pathname || '').includes('/payroll');
  const isEmployeeApprovalsUrl = (pathname || '').includes(
    '/employee-approvals'
  );
  const isWhiteBg = (pathname || '').includes('/dda-schedules');
  const isOfficeCalendarPage = pathname === '/office-calendar';
  const isSchedulePage = pathname === '/schedules';
  const isDashboardPage = pathname === '/dashboard';
  const isParticipantTrials = pathname === '/participant-trials';
  const isReviewRollSheetDetails =
    pathname.includes('/review-roll-sheets/') &&
    Number(pathname.split('/')?.[2] || 0) > 0;
  const isPIPRunTrial = pathname.endsWith('/run-trials');
  const hasTimerButton = canAccess(permissions.USE_TIMER);
  const isTimerRunningMobile = width < TAB_SIZE && isStopButton;
  const isSigned = !!currentUser.signature_font;
  const handleSignature = async (val, cb) => {
    try {
      const tPayload = {
        signature_font: String(val),
      };
      const res = await apiUpdateUserSignature(tPayload);
      if (res?.status) {
        NotificationHandler.showSuccess({
          description:
            res?.message ||
            'Your signature and initials were successfully saved.',
        });
        if (cb) cb();
        dispatch(syncUpdateProfile(tPayload));
      } else {
        NotificationHandler.showError({
          description: res?.message || 'Something went wrong!',
        });
      }
    } catch (error) {
      NotificationHandler.showError({
        description: 'Something went wrong!',
      });
    }
  };

  return (
    <Layout
      className={cs('gx-app-layout', classes.layoutWrapper, {
        [classes.isPIPRunTrial]: isPIPRunTrial,
        [classes.isFullScreen]: isParticipantTrials || isReviewRollSheetDetails,
      })}
    >
      {!isSigned && <SignatureModal onSubmit={handleSignature} />}
      <AlertPopup ref={AlertPopupHandler.setRef} />
      <Notifications ref={NotificationHandler.setRef} />
      <Sidebar />
      <Layout
        className={cs(classes.mainLayout, {
          [classes.whiteBg]: isWhiteBg,
          'layout-has-timer-button':
            hasTimerButton && width < TAB_SIZE && !isTimerRunningMobile,
          // 'layout-has-timer-button-running':
          //   hasTimerButton && isTimerRunningMobile,
        })}
        id="layout-inner"
      >
        <Topbar />
        <Content
          id="main-wrapper"
          className={cs(
            `gx-layout-content gx-container-wrap ${classes.layoutContent}`
          )}
        >
          <div
            className={cs(
              'gx-main-content-wrapper gx-mt-5 gx-mx-5 gx-p-0',
              classes.mainApp,
              {
                [classes.mainAppFlex]:
                  isParticipantScheduleUrl || isEmployeeApprovalsUrl,
                [classes.mainAppRelative]: isPayrollUrl,
                [classes.isDashboardPage]: isDashboardPage,
              },
              'main-inner-wrapper'
            )}
          >
            <Switch>
              <Route
                exact
                path={`/dashboard`}
                component={asyncComponent(() =>
                  import('views/pages/Dashboard')
                )}
              />
              <Route
                exact
                path={`/certificates`}
                component={asyncComponent(() =>
                  import('views/pages/LicensesCertificates')
                )}
              />
              <Route
                exact
                path={`/roll-sheet`}
                component={asyncComponent(() =>
                  import('views/pages/RollSheets')
                )}
              />
              <Route
                exact
                path={`/participants/:id/pip/:pipId`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantViewPIP')
                )}
              />
              <Route
                exact
                path={`/run-trials`}
                component={asyncComponent(() =>
                  import('views/pages/PIPRunTrial')
                )}
              />
              <Route
                exact
                path={`/my-trials-approvals`}
                component={asyncComponent(() =>
                  import('views/pages/TrialsApprovals')
                )}
              />
              <Route
                exact
                path={`/all-trials-approvals`}
                component={asyncComponent(() =>
                  import('views/pages/TrialsApprovals')
                )}
              />
              <Route
                exact
                path={`/review-roll-sheets`}
                component={asyncComponent(() =>
                  import('views/pages/RollSheetReview')
                )}
              />
              <Route
                exact
                path={`/review-roll-sheets/:id`}
                component={asyncComponent(() =>
                  import('views/pages/RollSheetReviewDetails')
                )}
              />
              <Route
                exact
                path={`/participant-trials`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantTrials')
                )}
              />
              <Route
                exact
                path={`/participants/:id/edit-active-pip/:pipId`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantNewPIP')
                )}
              />
              <Route
                exact
                path={`/participants/:id/edit-pip/:pipId`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantNewPIP')
                )}
              />
              <Route
                exact
                path={`/participants/:id/create-pip`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantNewPIP')
                )}
              />
              <Route
                exact
                path={`/participants/:id`}
                component={asyncComponent(() =>
                  import('views/pages/ParticipantProfile')
                )}
              />
              <Route
                exact
                path={`/participants`}
                component={asyncComponent(() =>
                  import('views/pages/Participants')
                )}
              />
              <Route
                exact
                path={`/employee-approvals`}
                component={asyncComponent(() =>
                  import('views/pages/EmployeeApprovals')
                )}
              />
              <Route
                exact
                path={`/roster`}
                component={asyncComponent(() => import('views/pages/Roster'))}
              />
              <Route
                exact
                path={`/programs`}
                component={asyncComponent(() => import('views/pages/Programs'))}
              />
              <Route
                exact
                path={`/facilities`}
                component={asyncComponent(() =>
                  import('views/pages/Facilities')
                )}
              />
              <Route
                exact
                path={`/employees/:id`}
                component={asyncComponent(() =>
                  import('views/pages/UserProfile')
                )}
              />
              <Route
                exact
                path={`/employees`}
                component={asyncComponent(() =>
                  import('views/pages/Employees')
                )}
              />
              <Route
                exact
                path={`/announcements`}
                component={asyncComponent(() =>
                  import('views/pages/Announcements')
                )}
              />
              <Route
                exact
                path={`/bonuses-reimbursements`}
                component={asyncComponent(() =>
                  import('views/pages/BonusesReimbursements')
                )}
              />
              <Route
                exact
                path={`/payroll`}
                component={asyncComponent(() => import('views/pages/Payroll'))}
              />
              <Route
                exact
                path={`/office-calendar`}
                component={asyncComponent(() =>
                  import('views/pages/OfficeCalendar')
                )}
              />
              <Route
                exact
                path={`/dda-schedules`}
                component={asyncComponent(() =>
                  import('views/pages/DDASchedules')
                )}
              />
              <Route
                exact
                path={`/schedules`}
                component={asyncComponent(() =>
                  import('views/pages/Schedules')
                )}
              />
              <Route
                exact
                path={`/reports`}
                component={asyncComponent(() => import('views/pages/Reports'))}
              />
              <Route
                exact
                path={`/services`}
                component={asyncComponent(() => import('views/pages/Services'))}
              />
              <Route
                exact
                path={`/locations`}
                component={asyncComponent(() => import('views/pages/Location'))}
              />
              <Route
                exact
                path={`/quickbooks/callback`}
                component={asyncComponent(() =>
                  import('views/pages/QuickbooksIntegration')
                )}
              />
              <Route
                exact
                path={`/quickbooks-integration`}
                component={asyncComponent(() =>
                  import('views/pages/QuickbooksIntegration')
                )}
              />
              <Route
                exact
                path={`/admin`}
                component={asyncComponent(() => import('views/pages/Admin'))}
              />
              <Route
                exact
                path={`/approvals`}
                component={asyncComponent(() =>
                  import('views/pages/Approvals')
                )}
              />
              <Route
                exact
                path={`/pending_auth`}
                component={asyncComponent(() =>
                  import('views/pages/Approvals/PendingAuth')
                )}
              />
              <Redirect from="*" to="/dashboard" />
            </Switch>
          </div>
          {!isSchedulePage &&
            !isPayrollUrl &&
            !isOfficeCalendarPage &&
            !isParticipantTrials &&
            !isReviewRollSheetDetails && (
              <Footer
                className={cs({
                  [classes.addMargin]: window.navigator.userAgent.match(
                    /iPhone/i
                  ),
                })}
              >
                <div className="gx-layout-footer-content">
                  ©Copyright Life Inc {new Date().getFullYear()}
                </div>
              </Footer>
            )}
        </Content>
      </Layout>
      <ViewAnnouncementsModal />
    </Layout>
  );
};
export default MainApp;
