import get from 'lodash/get';
import { apiUpdateUserSettings } from 'api/auth';
import { store } from 'store';
import Types from '../types/userSettings';

export const updateUserSettings = (data, cb) => {
  return async dispatch => {
    dispatch({
      type: Types.UPDATE_USER_SETTINGS_REQUEST,
    });
    // eslint-disable-next-line no-undef
    try {
      const resp = await apiUpdateUserSettings(data);
      const status = get(resp, 'status');
      const message = get(resp, 'message');
      if (status) {
        dispatch({
          type: Types.UPDATE_USER_SETTINGS_SUCCESS,
          payload: resp || {},
        });
        if (cb) cb(resp);
      } else {
        dispatch({
          type: Types.UPDATE_USER_SETTINGS_ERROR,
          payload: message,
        });
      }
    } catch (error) {
      dispatch({
        type: Types.UPDATE_USER_SETTINGS_ERROR,
        payload: error,
      });
    }
  };
};

export const setUserSettings = (key, attr, value, noApi) => {
  return async dispatch => {
    const stateData = store.getState();
    const userSettings = get(stateData, 'userSettings.settings') || {};
    const oldValue = get(userSettings, `settings.${key}.${attr}`) || '';
    if (oldValue !== value) {
      const newUserSettings = {
        ...userSettings,
        [key]: {
          ...userSettings[key],
          [attr]: value,
        },
      };
      dispatch({
        type: Types.SET_USER_SETTINGS,
        payload: { key, attr, value },
      });
      if (!noApi) {
        dispatch(updateUserSettings(newUserSettings));
      }
    }
  };
};
export const setWholeUserSettings = data => {
  return async dispatch => {
    dispatch({
      type: Types.SET_WHOLE_USER_SETTINGS,
      payload: data,
    });
  };
};
export const setOtherSettings = (key, attr, value) => {
  return async dispatch => {
    dispatch({
      type: Types.SET_OTHER_SETTINGS,
      payload: { key: key === 'settings' ? 'invalidKey' : key, attr, value },
    });
  };
};
export const setParticipantSettings = data => {
  return async dispatch => {
    dispatch({
      type: Types.SET_PARTICIPANT_SETTINGS,
      payload: data,
    });
  };
};
