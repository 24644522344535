import * as Yup from 'yup';

export const getSchema = () => {
  return Yup.object().shape({
    first_name: Yup.string().nullable(),
  });
};
export const getInitialValues = user => {
  const nameArr = (user?.name || '').split(' ');
  return {
    first_name: nameArr[0] || '',
    last_name: nameArr.filter((v, i) => i > 0).join(' ') || '',
  };
};
