import Types from '../types/certificate';

const initialState = {
  userCertificates: {
    loading: false,
    data: [],
    error: '',
    userId: '',
  },
  userExpiredCertificates: {
    loading: false,
    data: [],
    error: '',
  },
  certificates: {
    loading: false,
    data: {},
    error: '',
  },
  certificateApprovals: {
    loading: false,
    data: [],
    error: '',
  },
};

export default function Certificate(state = initialState, action) {
  switch (action.type) {
    case Types.GET_USER_CERTIFICATES_REQUEST:
      return {
        ...state,
        userCertificates: {
          ...state.userCertificates,
          loading: true,
          userId: action.userId,
          data:
            !!state.userCertificates?.userId &&
            state.userCertificates?.userId === action.userId
              ? [...(state.userCertificates?.data || [])]
              : [],
        },
      };
    case Types.GET_USER_CERTIFICATES_SUCCESS:
      return {
        ...state,
        userCertificates: {
          loading: false,
          data: action.payload,
          error: '',
          userId: state.userCertificates?.userId,
        },
      };
    case Types.GET_USER_CERTIFICATES_ERROR:
      return {
        ...state,
        userCertificates: {
          loading: false,
          data: [],
          error: action.payload,
          userId: state.userCertificates?.userId,
        },
      };
    case Types.GET_USER_EXPIRED_CERTIFICATES_REQUEST:
      return {
        ...state,
        userExpiredCertificates: {
          ...state.userExpiredCertificates,
          loading: true,
          userId: action.userId,
          data:
            !!state.userExpiredCertificates?.userId &&
            state.userExpiredCertificates?.userId === action.userId
              ? [...(state.userExpiredCertificates?.data || [])]
              : [],
        },
      };
    case Types.GET_USER_EXPIRED_CERTIFICATES_SUCCESS:
      return {
        ...state,
        userExpiredCertificates: {
          loading: false,
          data: action.payload,
          error: '',
          userId: state.userExpiredCertificates?.userId,
        },
      };
    case Types.GET_USER_EXPIRED_CERTIFICATES_ERROR:
      return {
        ...state,
        userExpiredCertificates: {
          loading: false,
          data: [],
          error: action.payload,
          userId: state.userExpiredCertificates?.userId,
        },
      };
    case Types.SYNC_UPDATE_CERTIFICATES:
      return {
        ...state,
        certificates: {
          ...state.certificates,
          data: {
            ...state.certificates.data,
            data: action.payload,
          },
        },
      };
    case Types.GET_CERTIFICATES_REQUEST:
      return {
        ...state,
        certificates: {
          ...state.certificates,
          loading: true,
        },
      };
    case Types.GET_CERTIFICATES_SUCCESS:
      return {
        ...state,
        certificates: {
          loading: false,
          data: action.isMore
            ? {
                ...state.certificates.data,
                ...action.payload,
                data: [...state.certificates.data.data, ...action.payload.data],
              }
            : action.payload,
          error: '',
        },
      };
    case Types.GET_CERTIFICATES_ERROR:
      return {
        ...state,
        certificates: {
          loading: false,
          data: {},
          error: action.payload,
        },
      };
    case Types.GET_CERTIFICATE_APPROVALS_REQUEST:
      return {
        ...state,
        certificateApprovals: {
          ...state.certificateApprovals,
          loading: true,
        },
      };
    case Types.GET_CERTIFICATE_APPROVALS_SUCCESS:
      return {
        ...state,
        certificateApprovals: {
          loading: false,
          data: action.payload,
          error: '',
        },
      };
    case Types.GET_CERTIFICATE_APPROVALS_ERROR:
      return {
        ...state,
        certificateApprovals: {
          loading: false,
          data: [],
          error: action.payload,
        },
      };
    default:
      return state;
  }
}
