import React, { useRef, useState } from 'react';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import Input from 'components/FormFields/FormikInput';
import Button from 'components/Button';
import Modal from 'components/Modal';
import { getSchema, getInitialValues } from './utils';
import classes from './SignatureModal.module.scss';

const fonts = [
  '"Cedarville Cursive", serif',
  '"Caveat", serif',
  '"Pecita", serif',
  '"Homemade Apple", serif',
  '"Bad Script", serif',
  '"Freehand", serif',
  '"Bonheur Royale", serif',
  '"Seaweed Script", serif',
  '"Shadows Into Light", serif',
  '"Permanent Marker", serif',
];

const SignatureModal = ({ onSubmit }) => {
  const formRef = useRef(null);
  const currentUser = useSelector(({ auth }) => auth.user);
  const initialValues = getInitialValues(currentUser);
  const [index, setIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const fontStyles = {
    fontFamily: fonts[index],
  };
  const renderEditForm = formProps => {
    const { values, handleSubmit } = formProps;
    const name = [values.first_name, values.last_name]
      .filter(v => !!v)
      .join(' ');
    const shortName = [values.first_name, values.last_name]
      .filter(v => !!v)
      .map(v => v[0])
      .join('');

    return (
      <form className={classes.formInner}>
        <div className={classes.names}>
          <Input
            name="first_name"
            label="First Name"
            size="large"
            placeholder=""
            hideErrorMessage
            textSize="md"
            isDisabled
          />
          <Input
            name="last_name"
            label="Last Name"
            size="large"
            placeholder=""
            hideErrorMessage
            textSize="md"
            isDisabled
          />
        </div>
        <p className={classes.description}>
          The signature below will be used for signing documents related to your
          responsibilities as a [role goes here]. Wherever a signature is
          required, you’ll see the option to input your digital signature. By
          clicking the corresponding button, the signature below will be
          inserted on your behalf. For more information, click{' '}
          <a href="#here" rel="noopener noreferrer">
            here
          </a>
          .
        </p>
        <div className={classes.signature}>
          <div>
            <label>Signature</label>
            <div style={fontStyles}>{name}</div>
          </div>
          <div>
            <label>Initials</label>
            <div style={fontStyles}>{shortName}</div>
          </div>
        </div>
        <div>
          <Button
            buttonType="button"
            onClick={() => {
              let newIndex = index + 1;
              if (index === fonts.length - 1) {
                newIndex = 0;
              }
              setIndex(newIndex);
            }}
            type="link"
            weight="md"
            className="gx-mb-0 gx-mt-2"
          >
            Regenerate
          </Button>
        </div>
        <div className={classes.footerWrapper}>
          <div className={classes.footer}>
            <Button
              buttonType="button"
              onClick={() => {
                handleSubmit(values);
              }}
              size="large"
              type="primary"
              loading={loading}
              fontSize="md"
            >
              Save Signature
            </Button>
          </div>
        </div>
      </form>
    );
  };

  return (
    <Modal
      visible={true}
      onCancel={() => {}}
      title="Add Signature"
      wrapClassName={classes.modalWrapper}
      width={556}
    >
      <div className={classes.modalContent}>
        <Formik
          initialValues={initialValues}
          validateOnMount
          validationSchema={getSchema()}
          enableReinitialize
          onSubmit={() => {
            setLoading(true);
            onSubmit(fonts[index], () => {
              setLoading(false);
            });
          }}
          innerRef={formRef}
        >
          {renderEditForm}
        </Formik>
      </div>
    </Modal>
  );
};

export default SignatureModal;
