import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import debounce from 'lodash/debounce';
import cs from 'classnames';
import { Select } from 'antd';
import classes from './Dropdown.module.scss';
import PropTypes from 'prop-types';

const { Option, OptGroup } = Select;

let isFetching = false;

const handleDebounceSearch = debounce((keyword, fn) => {
  fn(keyword);
}, 300);

const Dropdown = props => {
  const dispatch = useDispatch();
  const [search, setSearch] = useState('');

  const {
    className,
    options,
    label,
    wrapperClass,
    touched,
    error,
    showSearch,
    metadata,
    loadData,
    syncLoadData,
    loadMoreData,
    syncLoadMoreData,
    showError = true,
    getOptionValue,
    getOptionLabel,
    labelClass,
    afterLoadData,
    handleSearch,
    onAddMoreOptions,
    onDropdownVisibleChange,
    required,
    bgLightGray,
    innerRef,
    itemRound,
    isMultipleOptionCheckbox,
    dropdownClassName,
    bodyContainer,
    isGhost,
    textMedium,
    isCustomMultipleSelected,
    isLargeWithoutFontSize,
    onSearchChange,
    hasLoadMore,
    isSyncSearch,
    ...rest
  } = props;

  useEffect(() => {
    const fetchData = async () => {
      if (syncLoadData) {
        syncLoadData('', resp => {
          if (onAddMoreOptions) onAddMoreOptions();
          afterLoadData && afterLoadData(resp);
        });
      } else {
        const resp = loadData && (await dispatch(loadData('')));
        afterLoadData && afterLoadData(resp);
      }
    };

    fetchData();
    isFetching = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleLoadMore = async () => {
    if (!isFetching && metadata && metadata.current_page < metadata.last_page) {
      isFetching = true;

      if (syncLoadMoreData) {
        syncLoadMoreData(search, metadata.current_page + 1, () => {
          isFetching = false;
        });
      } else {
        await dispatch(loadMoreData(search, metadata.current_page + 1));
        isFetching = false;
      }
    }
  };

  const searchFn = v => {
    dispatch(loadData(v));
  };

  const handleSearchChange = v => {
    setSearch(v);
    if (onSearchChange) onSearchChange(v);
    if (loadData || syncLoadData) {
      if (syncLoadData) {
        handleDebounceSearch(v, syncLoadData);
      } else {
        handleDebounceSearch(v, searchFn);
        handleSearch && handleSearch(v);
      }
    }
  };

  const searchProps = showSearch
    ? {
        filterOption: false,
        onSearch: handleSearchChange,
        onPopupScroll: handleLoadMore,
      }
    : hasLoadMore
    ? {
        onPopupScroll: handleLoadMore,
      }
    : {};

  return (
    <div
      className={cs(wrapperClass, {
        [classes.bgLightGray]: bgLightGray,
        [classes.isLargeWithoutFontSize]: isLargeWithoutFontSize,
        [classes.itemRound]: itemRound,
        [classes.isGhost]: isGhost,
        [classes.textMedium]: textMedium,
      })}
    >
      {label ? (
        <div className={cs(classes.fieldTitle, labelClass)}>
          {label}
          {required && <span className={classes.required}>*</span>}
        </div>
      ) : null}
      <Select
        className={cs('gx-mb-0', {
          [className]: className,
          [classes['is-invalid']]: touched && error,
          [classes.isCustomMultipleSelected]: isCustomMultipleSelected,
        })}
        ref={innerRef}
        showSearch={showSearch}
        {...(showSearch || hasLoadMore ? searchProps : {})}
        {...rest}
        onDropdownVisibleChange={o => {
          if (onDropdownVisibleChange) onDropdownVisibleChange(o);
          if (showSearch && search) {
            handleSearchChange('');
          }
        }}
        getPopupContainer={trigger =>
          bodyContainer ? document.body : trigger.parentNode
        }
        dropdownClassName={cs(
          classes.dropdownStandard,
          {
            [classes.dropdownIsMultipleOptionCheckbox]: isMultipleOptionCheckbox,
          },
          dropdownClassName
        )}
      >
        {options
          .filter(opt => {
            if (isSyncSearch && !!search && !!opt.label) {
              const searchLowerCase = search.toLowerCase();
              const labelLowerCase = opt.label.toLowerCase();
              return labelLowerCase.includes(searchLowerCase);
            }
            return true;
          })
          .map(opt => {
            if (opt.isGroup) {
              return (
                <OptGroup
                  key={getOptionValue(opt)}
                  value={getOptionValue(opt)}
                  label={opt.label}
                >
                  {(opt.options || []).map(subOpt => {
                    return (
                      <Option
                        key={getOptionValue(subOpt)}
                        value={getOptionValue(subOpt)}
                      >
                        {getOptionLabel(subOpt)}
                      </Option>
                    );
                  })}
                </OptGroup>
              );
            }
            return (
              <Option key={getOptionValue(opt)} value={getOptionValue(opt)}>
                {getOptionLabel(opt)}
              </Option>
            );
          })}
      </Select>
      {showError && touched && error && (
        <div className={classes['invalid-feedback']}>{error}</div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  options: PropTypes.array.isRequired,
};

Dropdown.defaultProps = {
  getOptionValue: option => option.value,
  getOptionLabel: option => option.label,
};

export default Dropdown;
