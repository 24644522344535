import React from 'react';
import cn from 'classnames';
import NotificationItem from './NotificationItem';
import { ReactComponent as NotificationBellSvg } from 'assets/images/NotificationBell.svg';
import CustomScrollbars from 'util/CustomScrollbars';
import Auxiliary from 'util/Auxiliary';
import classes from './AppNotification.module.scss';

const noItemHeight = 124;
const noItemWithReasonHeight = 150;
const maxItems = 3;
const minusSpace = 16;

const getNotificationScrollHeight = notifications => {
  if (!notifications?.length) return 0;
  let scrollHeight = 0;
  const noLength = notifications.length;
  notifications.forEach((obj, index) => {
    if (index < 3) {
      scrollHeight =
        scrollHeight +
        (obj.miscellaneous?.deny_reason
          ? noItemWithReasonHeight
          : noItemHeight);
    }
  });
  return scrollHeight - minusSpace - (noLength > maxItems ? 10 : 0);
};

const AppNotification = props => {
  const { timeFormat, onClose, onClick } = props;
  const notifications = props.notifications || [];
  const scrollHeight = getNotificationScrollHeight(notifications);
  return (
    <Auxiliary>
      <div className="gx-popover-header">
        <h3 className="gx-mb-0">Notifications</h3>
        <i className="gx-icon-btn icon icon-charvlet-down" />
      </div>
      {notifications.length > 0 ? (
        <CustomScrollbars
          className="gx-popover-scroll"
          style={{ minHeight: scrollHeight }}
        >
          <ul className={cn('gx-sub-popover', classes.notifications)}>
            {notifications.map((notification, index) => (
              <NotificationItem
                key={index}
                notification={notification}
                showDot={!notification.read_at}
                timeFormat={timeFormat}
                onClose={onClose(notification)}
                onClick={onClick(notification)}
              />
            ))}
          </ul>
        </CustomScrollbars>
      ) : (
        <div className={classes.notFound}>
          <NotificationBellSvg />
          <span>You don’t have any new notifications</span>
        </div>
      )}
    </Auxiliary>
  );
};

export default AppNotification;
